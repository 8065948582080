import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Press 8-8-8\\@70% 1RM`}</p>
    <p>{`Band Pull Aparts 8-8-8`}</p>
    <p>{`then,`}</p>
    <p>{`10:00 AMRAP of:`}</p>
    <p>{`10-Push Jerk (115/75)`}</p>
    <p>{`10-Box Jump Overs (24/20)`}</p>
    <p>{`*`}{`*`}{`Bonus WOD`}{`*`}{`*`}</p>
    <p>{`2 Rounds for time of:`}</p>
    <p>{`25-KBS (53/35)`}</p>
    <p>{`15-Burpees`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      